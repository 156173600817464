import React, {useState, useEffect, useCallback, useRef} from 'react'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts, WarningModal } from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl, Token, ImagesUrl,  config,  selectStyles, menuLink, allowDelete, allowEdit, ParentUrl, API_URL, API_IMAGE} from '../component/include'
import Select from 'react-select'

import UsePagination from "../component/pageNumber";
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import {Type} from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { formatGroupLabel, getAge, getClassName } from '../component/globalFunction'
import { userToken } from '../component/authentication'

import { useDispatch, useSelector } from 'react-redux'
import Cookies from 'js-cookie'
import {encrypt} from '../component/authentication'
import { Link } from 'react-router-dom'

import { useLocation } from 'react-router-dom'
import Error403 from '../settings/error403'
import Student from './student'


const ViewStudents=()=> {
    const structure = useRef(null)
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    });
    
    const schoolSession  = useSelector(state => state.rootReducer.schoolSession);
    let location =  useLocation()
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const schoolClass  = useSelector(state => state.rootReducer.schoolClass);
    
    const [student, setStudent] = useState([]);
    const [termination, setTermination]=useState([])
    const [errors, setErrors] = useState({});
    const [states, setStates] = useState([]) 
    const [lga, setLga] = useState([]) 
    const [editStudent, setEditStudent] = useState(false); 

  
    const [allFamily, setAllFamily]= useState([])
    const [allStudents, setAllStudents] = useState([]);
    const Initials ={
     

        perPage:96,
        status:{ value: '', label: ''},
        gender:{ value: '', label: ''},
        studentClass:{ value: '', label: ''},
        admissionNumber:'',
        regSession:{ value: '', label: ''},
        cardView:{ value: 'list', label: 'List View'},
        applicationID:'',
        studentName:'',
        family_name:{ value: '', label: ''},
        learningType:{ value: '', label: ''},
}


    const [general, setGeneral] = useState(Initials)

    let [page, setPage] = useState(1);
    const PER_PAGE = Number(general.perPage);
  
    const count = Math.ceil(allStudents.length / PER_PAGE);
    const dataList = UsePagination(allStudents, PER_PAGE);
  
    const handleReset =()=>{
      setGeneral(Initials)
  }
  

  const handleSelectDocument = (option, action)=>{
    setGeneral({...general, [action.name]: option });
   setErrors({...errors, [action.name]:''})
};

    const handleChangePage = (e, num) => {
      setPage(num);
      dataList.jump(num);
    };

    var pages = [...Array(dataList.maxPage).keys()].map(i =>  i+1);

   

    const handleFetchAllStudents =()=>{
        setNotice({...notice,  isLoading: true})
     
    const fd = new FormData();
    fd.append("regSession", general.regSession.value);
    fd.append("studentName", general.studentName);
    fd.append("applicationID", general.applicationID);
    fd.append("admissionNumber", general.admissionNumber);
    fd.append("learningType", general.learningType.value);
    fd.append("family_name", general.family_name.value);
    fd.append("studentClass", general.studentClass.value);
    fd.append("status", general.status.value);
    fd.append("gender", general.gender.value);

    let url = API_URL+'/students/index';
    axios.post(url, fd, config).then(result=>{

      if(Array.isArray(result.data.data)){
        setAllStudents(result.data.data)
    }else{
      setAllStudents([])
    }

    })
    .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
    }).finally(()=>{
        setNotice({...notice,  isLoading: false})
    })
    }


    

const handleFetchState =async(iso2)=>{

  let url = API_URL+'/geo/states/'+iso2
 await  axios.get(url, config).then(result =>{
    if(result.data.data.length!==0){
        setStates(result.data.data)
      }else{
          setStates([])
      }

  })

}


const handleFetchLga =async(alias)=>{

  let url = API_URL+'/geo/cities/'+alias
 await  axios.get(url, config).then(result =>{
    if(result.data.data.length!==0){
        setLga(result.data.data)
      }else{
          setLga([])
      }

  })

} 

  

  

      const  handleFetchStudent =(code)=>{
    
      
        let url = API_URL+'/student/profile/'+code;
        setNotice({...notice,  isLoading: true });
        axios.get(url, config)
        .then(result => {
            setStudent(result.data.data)
            setEditStudent(true);
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, isLoading: false
            }); 
        })   
            }
    
    

     const handleChangeGeneral = event =>{
		const {name, value} = event.target
        setGeneral({...general, [name] : value });
        setErrors({...errors, [name]:''})
     }

    
  
    const handleDelete = (code)=>{  
        setNotice({...notice,  isLoading: true}) 
           let close =   document.getElementById('btnWarningDialog-'+code)
              close.click();
            let url = API_URL+'/student/delete/'+code;
           axios.get(url,  config)
           .then(response =>{
            if(response.data.status ===200){
               Alerts('Saved!', 'success', response.data.message)
                   } else{
                    setErrors({...errors, errorMessage: response.data.message})
                   }   
           })
           .catch((error)=>{
            setErrors({...errors, errorMessage: error.message})
           }).finally(()=>{
            handleFetchAllStudents()
               setNotice({...notice,  isLoading: false })
           }) 
    }


   
    const handleEditStudent =(row)=>{
    //setStudentId(row.code);
    handleFetchStudent(row.code)
   handleFetchState(row.country)
   handleFetchLga(row.stateId)  
    //setActiveStep(1); 

 structure.current.scrollIntoView({ behavior: "smooth" });
    }


const getTermination =(code)=>{

  const ans = termination.filter(item=>item.code===code)
  const final = ans.length!==0?ans[0].terminationName:'Not Studying'
return final

}


const fetchOccupation =()=>{
  var sql ="SELECT occupation, document, house, club, position, terminationReason, program from  tbl_student_master where ID="+1;
const fd = new FormData();
fd.append("sql", sql);
			let url = API_URL+'/exam/result/fetch_raw'
axios.post(url, fd, config).then(result=>{
if(result.data.data.length>0){
setTermination(JSON.parse(result.data.data[0].terminationReason))
}
})
.catch((error)=>{
Alerts('Error!', 'danger', error.message)
})
}


    const tableHeader = [
        {dataField: 'passport', text: 'Photo', editable: false, formatter:(cell, row)=><img style={{ height:'40px', height:'40px' }} className="img-40" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+"/"+row.gender+".png"}} src={API_IMAGE+'/students/'+cell} title={row.studentName}  alt={row.studentName} />},
        
        {dataField: 'admissionNumber', text: 'Admission Number', sort: true}, 
        {dataField: 'studentName', text: 'Student Name',
        classes: 'fixed-column', 
    headerClasses: 'fixed-column',
        sort: true, validator: (newValue, row, column) => {
            if (!newValue) {
              return {
                valid: false,
                message: 'Student Name field is required'
              };
            }
            return true;
          }, formatter:(cell, row)=><div>
              {cell}<br/> {row.admissionStatus==='Studying'?<span className="pcoded-badge label label-success">{row.admissionStatus}</span>:row.admissionStatus==='Graduated'? <span className="pcoded-badge label bg-dark">{row.admissionStatus}</span>:<span className="pcoded-badge label label-danger">{getTermination(row.termination_reason)}</span>}
          </div>},
        {dataField: 'fatherName',  text: 'Father Name',  sort: true},
        {dataField: 'sectionID',  text: 'Current Class', sort: true, editable:false, formatter: (cell, row) => getClassName(row.classID, row.sectionID, schoolClass)}, 
        {dataField: 'dateOfBirth',  text: 'Age',  sort: true, editor: {
            type: Type.DATE
          }, formatter:(cell)=>getAge(cell)},
        {dataField: 'gender',  text: 'Sex',  sort: true, editor: {
            type: Type.SELECT, options:[
                        {value:'Male', label:'Male'},
                        {value:'Female', label:'Female'}
            ] }},
        {dataField: 'dateRegister', text: 'Registered date',  sort: true, editor: {
            type: Type.DATE
          } },
          {dataField: 'code', text: 'Action', formatter: (cell, row)=><div>


<WarningModal message="This is very dangerous, you shouldn't do it! are you really really sure.?" handleAction={()=>handleDelete(row.code)} mID={row.code} /> 

<div className="btn-group dropdown-split-success ">
        <button type="button" className="btn btn-sm btn-outline-success dropdown-toggle dropdown-toggle-split waves-effect waves-light" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i className="fa fa-hand"></i>Action
        </button>
        <div className="dropdown-menu" x-placement="bottom-start" style={{ position: 'absolute', transform: 'translate3d(110px, 40px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>
          


        <Link to={"/student/profile/"+row.code} className="dropdown-item waves-effect waves-light">  <i className='fa fa-graduation-cap'></i> Preview Student</Link>


        {allowEdit==='Yes'?   <a className="dropdown-item waves-effect waves-light" onClick={()=>handleEditStudent(row)} href="#!"><i className="icofont icofont-edit-alt"></i> Edit Student</a>:[]}


            <a className="dropdown-item waves-effect waves-light"  href="#!" onClick={()=>loginAsStudent(row)} >  <i className='fa fa-user'></i> Login as this Student</a>
            <div className="dropdown-divider"></div>
            <a className="dropdown-item waves-effect waves-light"  href={'/wallet/transactions/'+row.code}  >  <i className='fa fa-newspaper-o'></i> Add Transaction</a>

            {allowDelete==='Yes'?  <a href="#!" data-toggle="modal" className="dropdown-item delete"  data-target={`#warningdialog-${row.code}`} title="Delete"><i className="fa fa-trash"></i>Delete Student</a>:[]}
        </div>
    </div>

          </div>, editable: false},
     ];
    
     const StudentTable=(props)=>{

        const CustomToggleList = ({
            columns,
            onColumnToggle,
            toggles
          }) => ( 
          <div  className="btn-group btn-group-toggle btn-group-vertical" data-toggle="buttons">
              {
                columns
                  .map(column => ({
                    ...column,
                    toggle: toggles[column.dataField]
                  }))
                  .map(column => (
                    
                    <button
                      type="button"
                      key={ column.dataField }
                      className={ ` btn btn-primary ${column.toggle ? 'active' : ''}` }
                      data-toggle="button"
                      aria-pressed={ column.toggle ? 'true' : 'false' }
                      onClick={ () => onColumnToggle(column.dataField) }
                    >
                      { column.text }
                    </button>
                  ))
              }
            </div>
            
          );
  
        const handleUpdate=(column, newValue, code)=>{       
         const fd = new FormData(); 
         fd.append('newValue', newValue);
         fd.append('column', column.dataField);
         fd.append('code', code);
         let url = API_URL+'/student/change_field'
         axios.post(url, fd, config)
             //.then(result => console.log(result.data))
             .then()
             .catch((error)=>Alerts('Error!', 'danger', error.message))

           
         } 
         const { SearchBar } = Search;
     
         const customTotal = (from, to, size) => (
            <span >&nbsp;Showing { from } to { to } of { size } items</span>
          );
         const options = {
             showTotal: true,
         paginationTotalRenderer: customTotal,
         sizePerPageList: [{text: '100', value: 100}, {text: '500', value: 500}, {text: '1000', value: 1000},
         { text: 'All', value: props.data.length }]
            
           };
      
         return <ToolkitProvider search columnToggle 
                     keyField='id' data={ props.data } columns={ props.columns } >
                         
                         {
                             props => (
                             <div >
                               <div className="form-group">
                                 <SearchBar
                                 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                                 &nbsp;
                               
                                 <div className="btn-group dropdown-split-success">
      
      <button type="button" className="btn btn-primary dropdown-toggle dropdown-toggle-split waves-effect waves-light" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <i className="fa fa-search-plus"></i> Columns
      </button>
      
      <div className="dropdown-menu" x-placement="bottom-start" style={{overflow:'scroll', height:'260px', width:'250px', position: 'absolute', transform: 'translate3d(113px, 40px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>
        <CustomToggleList { ...props.columnToggleProps } />
      </div>
      </div>
      
      <div className="dt-buttons btn-group pull-right"> 

      <Link to={"/students/registration"}  className="btn btn-outline-success waves-effect waves-light">  <i className="fa fa-plus"></i>Add New </Link>

	


    <div className="btn-group dropdown-split-primary ">




        <button type="button" className="btn btn-outline-primary dropdown-toggle dropdown-toggle-split waves-effect waves-light" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i className="fa fa-download"></i>Export
        </button>
        <div className="dropdown-menu" x-placement="bottom-start" style={{ position: 'absolute', transform: 'translate3d(110px, 40px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>
          
        <form method='POST' action={API_URL+'/download_file/student/index'} target='_blank'>
                <input type='hidden' name='gender' value ={general.gender.value} />
                <input type='hidden' name='status' value ={general.status.value} />
                <input type='hidden' name='studentClass' value ={general.studentClass.value} />
                
                <input type='hidden' name='learningType' value ={general.learningType.value} />

                <input type='hidden' name='admissionNumber' value ={general.admissionNumber} />
                <input type='hidden' name='regSession' value ={general.regSession.value} />
               
        <button type="submit" className="btn dropdown-item waves-effect waves-light" > Export To Excel</button>
        
        </form>

        <form method='POST' action={ServerUrl+'/printout/student_records.php?token='+userToken} target='_blank'>

        <input type='hidden' name='studentlist' value ={JSON.stringify(allStudents, null, 2)} />



                <input type='hidden' name='gender' value ={general.gender.value} />
                <input type='hidden' name='status' value ={general.status.value} />
                <input type='hidden' name='studentClass' value ={general.studentClass.value} />

                <input type='hidden' name='admissionNumber' value ={general.admissionNumber} />
                <input type='hidden' name='regSession' value ={general.regSession.value} />

                <input type='hidden' name='jwtToken' value ={Token} />
        <button type="submit" className="btn dropdown-item waves-effect waves-light" > Print Preview</button>
        
        </form>

        </div>
    </div> 
    </div> 

                                 </div>
                                 
                          
                 
                                 <BootstrapTable
                                 { ...props.baseProps }
                                             striped
                                         hover
                                         condensed
                                         noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                     pagination={paginationFactory(options) }
                                     cellEdit={ cellEditFactory({
                                       mode: 'dbclick',
                                       blurToSave: true,
                                       autoSelectText: true,
                             beforeSaveCell: (oldValue, newValue, row, column) => {
                               if(oldValue !==newValue){
                               handleUpdate(column, newValue, row.code);
                               
                              return props.fetchdata;
                             }
                             
                           }
                                     }) }
                                 />
                             </div>
                             )
                         }
      </ToolkitProvider>
      }
    



    const loginAsStudent =(row)=>{
      //1000 * 60 * SESSION_IDEL_MINUTES

       
    var inSixtyMinutes = Date.now() + 59 * 60 * 1000;
    var inOneMinutes = Date.now() + 58 * 60 * 1000;
    var inOneHours = new Date(new Date().getTime() + 59 * 60 * 1000);
    let domain = '.aduvieportal.org'

Cookies.set("_stbexpt", inOneMinutes, { expires: inOneHours, domain: domain });
Cookies.set("_stexpt", inSixtyMinutes, { expires: inOneHours, domain: domain });


 Cookies.set("stkauscd", row.code, { expires: inOneHours, domain: domain });
 Cookies.set("stkamtk", Token, { expires: inOneHours, domain: domain  });
 Cookies.set("stkstnm", encrypt(row.studentName), { expires: inOneHours, domain: domain  });
 Cookies.set("fmcdk", row.family_name, { expires: inOneHours, domain: domain  });
 Cookies.set("sclkysd", row.classID, { expires: inOneHours, domain: domain  });
 Cookies.set("ssckayd", row.sectionID, { expires: inOneHours, domain: domain  });

       window.open(ParentUrl+'/studentportal/dashboard', '_blank') 

     }
     


    


      
const fetchFamily =()=>{
  let url = API_URL + '/fetch/data/tbl_family'
    axios.get(url, config).then(result=>setAllFamily(result.data.data))
  }

        const menu  = useSelector(state => state.rootReducer.sidebar);
useEffect(()=>{   
  
  //setIsAuthenticated(menuLink.includes(location.pathname))
  fetchFamily() 
  fetchOccupation()
},[]);
        return (<>
          <div  className="main-body">
          {isAuthenticated?   <div className="page-wrapper">
                        {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }

        <PageHeader title="Students">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-graduation-cap"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><Link to="/students/view_students"> Students</Link></li> 
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                               {/*  <!-- Edit student start here  --> */}
                               <div ref={structure} />



                               {errors.errorMessage?
<div className="alert alert-danger background-danger">
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <i className=" fa fa-times text-white"></i>
        </button>
        <strong> {errors.errorMessage}</strong> 
    </div>:''}

                   {editStudent ?  <Student 
                   student={student}
                   state={states}
                   lga={lga}
                   setEditStudent={setEditStudent}
                   
                   />:
                   
                   
                   

<div className="row  no-print">
        <div className="col-sm-12">
<div className="card z-depth-0">
<div className="card-header">
<h5><i className="fa fa-edit" ></i> Search Student</h5>
<div className="card-header-right">
<ul className="list-unstyled card-option">
<li><i className="feather icon-maximizes full-card"></i></li>
<li><i className="feather icon-minus minimize-card"></i></li>
<li><i className="feather icon-trash-2 close-card"></i></li>
</ul>
</div>
</div>
<div className="card-block">

<div className="row">

            <section className="col-md-4">
                    <div className="form-group">
<label > Search By Gender</label> 
<Select  options={[
{ value: 'Male', label: 'Male' },
{ value: 'Female', label: 'Female' },
]} 
onChange={handleSelectDocument}  name="gender" value={general.gender}   /> 
                    </div>
</section>

            

<section className="col-md-4">
                    <div className="form-group">
<label > Search By  Status</label> 
<Select  options={[
{ value: 'Studying', label: 'Studying' },
{ value: 'Graduated', label: 'Graduated' },
{ value: 'Terminated', label: 'Terminated' },
]} 

onChange={handleSelectDocument}  name="status" value={general.status}   /> 
                    </div>
</section>
<section className="col-md-4">
<div className="form-group">
<label > Search By  Class </label>
<Select  options={
    schoolClass.map((list, idx)=> {
       return {key:idx, value: list.ID, label: list.cname, options:list.section.map((sec, id)=>{ 
           return sec.sname !==''? {key:id, classID:list.ID, isSection:'1', value: sec.ID, label: sec.sname}:{classID:list.ID, value: list.ID, label: list.cname, isSection:'0'}
       }) }
     })
     
} 
formatGroupLabel={formatGroupLabel}
onChange={handleSelectDocument}  name="studentClass" value={general.studentClass}  /> 
                
            </div>
            </section> 



<section className="col-md-4">
                    <div className="form-group">
<label > Search By  Admission Number</label> 
<input type="text" className="form-control" name="admissionNumber" value={general.admissionNumber}  onChange={handleChangeGeneral} />
                    </div>
</section>


<section className="col-md-4">
                    <div className="form-group">
<label > Search By  Student Name</label> 
<input type="text" className="form-control" name="studentName" value={general.studentName}  onChange={handleChangeGeneral} />
                    </div>
</section>


<section className="col-md-4">
                    <div className="form-group">
<label > Search By  Application Number</label> 
<input type="text" className="form-control" name="applicationID" value={general.applicationID}  onChange={handleChangeGeneral} />
                    </div>
</section>


<section className="col-md-4">

<div className="form-group">
<label >Search By  Admission Session</label>
<Select   options={schoolSession.map((dat, id)=> {
            return {key:id, value:dat.startYear, label:dat.startYear};
          })}
onChange={handleSelectDocument}  name="regSession" value={general.regSession} styles={selectStyles} /> 
                        
                    </div>


</section>

<section className="col-md-4">

<div className="form-group">
<label >Search By  Family Name</label>
<Select   options={allFamily&&allFamily.map((list, idx)=> {
               return {key:idx, value: list.code, label:list.familyName}
             })
        } 
onChange={handleSelectDocument}  name="family_name" value={general.family_name} styles={selectStyles} /> 
 
</div>


</section>


<div className="col-md-4">


<div className="form-group">
<label > Learning Type</label>
<Select  options={[
{ value: 'Boarder', label: 'Boarder' },
{ value: 'Day', label: 'Day' },
]} 
onChange={handleSelectDocument}  name="learningType" value={general.learningType} styles={selectStyles}  /> 
</div>  
</div>


<section className="col-md-4">
                    <div className="form-group">
<label > Page Layout</label> 
<Select  options={[
{ value: 'card', label: 'Card View' },
{ value: 'list', label: 'List View' },
]} 
onChange={handleSelectDocument}  name="cardView" value={general.cardView}   /> 
                    </div>
</section>
 </div>


<div className="row">
<div className="col-md-6 ">


<button type="button" onClick={handleReset} className="btn btn-inverse "><i className="fa fa-refresh"></i> Reset</button>&nbsp;&nbsp;
<button type="button"  className="btn btn-danger " onClick={handleFetchAllStudents}><i className="fa fa-search"></i> Search Student</button>
</div>	                </div>
    </div>
    </div>

    </div>
</div>
}
                               
                               
                              
                                    <div className="row  ">
                                    <div className="col-sm-12">
                               

                               
{general.cardView.value==='card'?
                <div className="card z-depth-0">
                           <div className="table-responsive">
       {allStudents.length!==0?     <div className="card-block">
    <div className="row">
        {allStudents&&dataList.currentData().map((std, id)=>
        <section key={id} className="col-md-3">
            <button type="button"   className='btn btn-default' style={{width:'100%'}}> 
           
           
            <img style={{ textAlign:'center', height:'100px', width:'100px'}} className="img-100" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/'+std.gender+'.png'}} src={`${API_IMAGE}/students/${std.passport}`} alt="" />

            <div ><b>{ std.studentName.length>20?std.studentName.slice(0, 17)+'...':std.studentName}</b></div>
            <label>({std.admissionNumber})</label>
            <div>{getClassName(std.classID, std.sectionID, schoolClass)}</div>
            
<div style={{width:'50%', textAlign:'left', float:'left', fontSize:'13px'}}>Reg No <br/>Gender<br/>Status</div>
<div style={{width:'50%', textAlign:'right', float:'right', fontSize:'13px'}}>{std.registerNumber} <br/>{std.gender}<br/> {std.admissionStatus==='Studying'?<span className="pcoded-badge label label-success">{std.admissionStatus}</span>:std.admissionStatus==='Graduated'? <span className="pcoded-badge label bg-dark">{std.admissionStatus}</span>:<span className="pcoded-badge label label-danger">{std.admissionStatus}</span>}</div>
            </button>
                <div className="input-group" >

                {allowEdit==='Yes'? <button type="button"  onClick={()=>handleEditStudent(std)}   className="btn btn-warning form-control btn-sm" >  <i className='fa fa-edit'></i> Edit</button>:[]}

                
                {allowDelete==='Yes'?   <button type="button" name="delete" data-toggle="modal"  data-target={`#warningdialog-${std.code}`}   className="btn btn-danger  delete btn-sm" >  <i className='fa fa-trash'></i> </button>:[]}
                    <WarningModal message="This is very dangerous, you shouldn't do it! are you really really sure.?" handleAction={()=>handleDelete(std.code)} mID={std.code} /> 


                    <Link to={"/student/profile/"+std.code}   className="form-control btn btn-inverse btn-sm"><i className='fa fa-graduation-cap'></i> Profile </Link>

                </div>
            </section>
            
)}



</div>








{allStudents && allStudents.length!==0?
<div className="row">
<div className="col-md-6">
   
        <div className=' form-group'>
        <select name='perPage' value={general.perPage}  style={{width:'60px', height:'30px'}} onChange={handleChangeGeneral}>
            <option value='96'>96</option>
            <option value='384'>384</option>
            <option value='768'>768</option>
            <option value='1536'>1536</option>
            <option value={allStudents.length }>All</option>
        </select>&nbsp;
        <span >Showing 1 to { dataList.currentData().length } of { allStudents.length } items
        </span>
        

        </div>
        </div>
        <div className="col-md-6 ">
       
            <div className="dataTables_paginate paging_simple_numbers" id="base-style_paginate">
                <ul className="pagination">

                <li className={page === 1 ? ' paginate_button page-item previous disabled' : 'paginate_button page-item previous'}  >
          <a className="page-link" href="#!" onClick={(e) =>handleChangePage(e, page - 1)} ><i className="czi-arrow-left mr-2"></i>Previous</a></li>

    {pages.map((item, index) =>
    <li key={index} className={page===item?' paginate_button page-item active':'paginate_button page-item'}><a className="page-link" href="#!" onClick={(e) =>handleChangePage(e, item)}>{item}</a></li>
            
         )}
              
        <li className={page === dataList.maxPage  ? ' paginate_button page-item next disabled' : 'paginate_button page-item next'}  ><a className="page-link" href="#!" onClick={(e) =>handleChangePage(e, page + 1)} aria-label="Next">Next</a></li>

                </ul>
                </div>
                
                
                </div>


           </div>:''}

    </div>:''}</div></div>


:
                                                
<div className="card z-depth-0">
            <div className="card-header">
                <h5>Students List</h5>
                <div className="card-header-right">
                    <ul className="list-unstyled card-option">
                        <li><i className="feather icon-maximizes full-card"></i></li>
                        <li><i className="feather icon-minus minimize-card"></i></li>
                        <li><i className="feather icon-trash-2 close-card"></i></li>
                    </ul>
                </div>
            </div>
            <div className="card-block">
		  <div className="card z-depth-0">
          <div className="card-block">
            <div className="col-md-12">
          <div className="table-responsive">
        {notice.isDataFetching ? <TableLoader />:
        <StudentTable data={allStudents}  columns={tableHeader}  />}
            </div></div>
</div>
</div>
</div>
    
    </div>}



    
    </div>
    </div>
    
    
    
    </div>
               
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>


</> 
 );
        
}

export default React.memo(ViewStudents) 